.navbar-wrapper {
  display: flex;
  justify-content: space-between;
  height: 7rem;
  margin: none;
  background-color: rgb(40, 42, 58);
  color: white;
  width: 100%;
}

.navbar-wrapper ul {
  /* width: 10rem; */
}

.navbar-wrapper li {
  padding: .4rem;
}

.top-left-navbar {
  display: flex;
  align-items: center;
}

.top-left-navbar li {
  margin-left: 1rem;
  margin-right: 1rem;
}

.profile-button-login-wrapper {
  display: flex;
  align-items: center;
}

.profile-button-wrapper {
  display: flex;
  align-items: center;
  top: 4rem;
  right: 8rem;
}

.profile-icon-button {
  padding-top: 3rem;
  padding-right: 11.5rem;
}

.hidden {
  display: none;
}

ul {
  font-weight: bold;
  list-style-type: none;
}


@media only screen and (max-width: 680px) {
  .navbar-wrapper {
    width: 100%;
    height: 14rem;
  }
  .top-left-navbar {
    height: 100%;
    width: 100%;
  }
  .profile-button-login-wrapper {
    width: 0%;
  }
  .profile-icon-button {
    padding-right: 20rem;
  }
}
