.single-details-page-wrapper {
    display: flex;
    justify-content: center;
    min-height: 100rem;
    background-color: #0F0E0E;
    color: white;
    height: fit-content;
    border-radius: 3px;
}

.single-details-page-inner-wrapper {
    display: flex;
    flex-direction: column;
    background-color: #0F0E0E;
    width: 70rem;
    min-height: 80rem;
    height: fit-content;
    border-radius: 3px;
    margin-top: 2rem;
}


.single-details-page-left-right-column {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-height: 30rem;
    margin-bottom: 2rem;
}

.single-details-page-left-column {
    width: 100%;
}

.single-details-page-left-column > img {
    border-radius: 10px;
    height: 30rem;
    max-width: 52rem;
}

.single-game-details-right-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 20rem;
}

.add-to-cart {
    display: flex;
    justify-content: center;
    /* text-align: center; */
}

.add-to-cart:hover {
    background-color: #282A3A;
}

.singleGameDetails-game-details {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid gray;
}

.singleGameDetails-game-details-tag {
    color: rgb(143, 126, 126);
}

@media only screen and (max-width: 680px) {
    .single-details-page-inner-wrapper {
        width: 23rem;
    }
    .carouse2l {
        display: none;
    }

    .single-details-page-left-column > img {
        max-width: 10rem;
    }

    .single-game-details-right-column {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 20rem;
        margin-right: 1rem;
    }

    .singleGameDetails-game-details-tag{
        font-size: .8rem;
    }
}
