@import url(https://fonts.googleapis.com/css?family=Poppins);
#modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

#modal-background {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

#modal-content {
  position: absolute;
  background-color:white;
}

@media only screen and (max-width: 680px) {
  #modal {
    width: 80%;
  }
  #modal-content {
    width: 50%;
    margin-right: 15rem;
  }
}



.open-modal-button {
    background-color:rgb(115, 95, 50);
    box-shadow: 0 2px 4px black;
    border: line;
    color: white;
    font-weight: bold;
    padding: 10px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 10px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.open-modal-button:hover {
    background-color: beige;
}

.submit-new-application-link {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10rem;
}


button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

button.disabled:hover {
  background-color: gray;
}

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  max-height: 40rem;
  padding: 30px;
  background-color: #fff;
  border-radius: 30px;
  overflow-y: auto;
}

.modal h1 {
  margin-top: 0;
}

.modal form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.modal label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 10px;
}

.modal input {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #272121;
}

.modal button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: black;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border: 2px solid #333;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  width: 100%;
}

.modal button:hover {
  background-color: rgb(40, 42, 58);
}

.modal .error {
  color: red;
  margin-top: 10px;
}

.login-form-demo {
  text-decoration: underline;
  border-bottom: 1px solid rgb(206, 89, 89);
}



.submit {
  text-decoration: underline;
  border-bottom: 1px solid rgb(206, 89, 89);
}

.top-right-wrapper {
    display: flex;
    align-items: center;
}

.profile-button-login-wrapper {
    display: flex;
    flex-direction: column;
}

.profile-dropdown {
    background-color: rgb(40, 42, 58);
    width: 10rem;
    margin-right: 4rem;
    text-align: center;
    border-radius: 10px;
    padding-bottom: .5rem;
    padding-top: .5rem;
    border: 2px solid #C69749;
}

@media only screen and (max-width: 680px) {
    .navbar-wrapper {
      width: 100%;
    }
    .profile-dropdown {
        margin-right: 20rem;
    }
  }

.navbar-wrapper {
  display: flex;
  justify-content: space-between;
  height: 7rem;
  margin: none;
  background-color: rgb(40, 42, 58);
  color: white;
  width: 100%;
}

.navbar-wrapper ul {
  /* width: 10rem; */
}

.navbar-wrapper li {
  padding: .4rem;
}

.top-left-navbar {
  display: flex;
  align-items: center;
}

.top-left-navbar li {
  margin-left: 1rem;
  margin-right: 1rem;
}

.profile-button-login-wrapper {
  display: flex;
  align-items: center;
}

.profile-button-wrapper {
  display: flex;
  align-items: center;
  top: 4rem;
  right: 8rem;
}

.profile-icon-button {
  padding-top: 3rem;
  padding-right: 11.5rem;
}

.hidden {
  display: none;
}

ul {
  font-weight: bold;
  list-style-type: none;
}


@media only screen and (max-width: 680px) {
  .navbar-wrapper {
    width: 100%;
    height: 14rem;
  }
  .top-left-navbar {
    height: 100%;
    width: 100%;
  }
  .profile-button-login-wrapper {
    width: 0%;
  }
  .profile-icon-button {
    padding-right: 20rem;
  }
}

.global-outer-container {
    display: flex;
    justify-content: center;
    min-height: 150rem;
    background-color: black;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 3px;
}

.global-inner-container {
    display: flex;
    flex-direction: column;
    background-color: black;
    width: 70rem;
    min-height: 80rem;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 3px;
    margin-top: 5.5rem;
}

.search-bar {
    display: flex;
    align-items: center;
    width: 15rem;
    background-color:rgb(40, 42, 58);
    border-radius: 10px;
    padding: .5rem;
  }

  .search-input {
    flex: 1 1;
    border: none;
    background: none;
    outline: none;
    padding: 5px;
  }

  .search-button {
    border: none;
    background: none;
    outline: none;
    padding: 5px;
    cursor: pointer;
  }

  .top-bar-homepage-container {
    display: flex;
    justify-content: space-between;
  }

  .top-bar-homepage-container-left-column {
    display: flex;
    align-items: center;
    text-align: center;
    width: 50rem;
  }

  .top-bar-homepage-container-right-column {
    display: flex;
    align-items: center;
  }

  @media only screen and (max-width: 680px) {
    .global-inner-container {
      width: 23rem;
    }
  }

.featured-games-homepage-wrapper {
    display: grid;
    grid-template-columns: 5fr 1fr;
    grid-gap: 0.5rem;
    width: 100%;
    height: 35rem;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .featured-games-homepage-big-picture-left-column {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  .featured-games-homepage-big-picture-left-column.slide-in img {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    /* transform: translateX(100%); */
    transition: -webkit-transform 0.2s ease;
    transition: transform 0.2s ease;
    transition: transform 0.2s ease, -webkit-transform 0.2s ease;
  }

  /* .featured-games-homepage-big-picture-left-column.slide-in img:first-child {
    transform: translateX(0%);
  } */

  .featured-games-homepage-big-picture-left-column.slide-in img {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    opacity: .25;
  }

  .featured-games-homepage-big-picture-left-column img {
    display: inline-block;
    height: 36rem;
    width: 55rem;
    border-radius: 10px;
    transition: -webkit-filter 0.3s;
    transition: filter 0.3s;
    transition: filter 0.3s, -webkit-filter 0.3s;
  }

  .featured-games-homepage-big-picture-left-column img:hover {
    -webkit-filter: brightness(0.9);
            filter: brightness(0.9);
    cursor: pointer;
  }

  .featured-games-homepage-little-pictures-right-column {
    display: flex;
    flex-direction: column;
    padding: 0.2rem;
  }

  .featured-games-homepage-little-pictures-right-column-container {
    display: flex;
    transition: -webkit-transform 0.5s ease;
    transition: transform 0.5s ease;
    transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  }

  .featured-games-homepage-little-pictures-right-column-image-title-card {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 12px;
    color: white;
    border-radius: 5px;
    margin: 2px;
    cursor: pointer;
  }

  .featured-games-homepage-little-pictures-right-column-image-title-card:hover {
    background-color: rgb(40, 42, 58);
  }

  .featured-games-homepage-little-pictures-right-column-image-title-card img {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4.6rem;
    width: 6rem;
    padding: 0.5rem;
    border-radius: 5px;
  }

  .featured-games-homepage-little-pictures-right-column-image-title-card-image-title {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  @media only screen and (max-width: 680px) {
    /* Adjust the layout to stack elements vertically */
    /* You can target specific elements as needed */
    /* .featured-games-homepage-wrapper {

      flex-direction: column;
    } */
    .featured-games-homepage-wrapper {
      display: grid;
      grid-template-columns: 5fr 1fr;
      grid-gap: 0.5rem;
      width: 60%;
      height: 35rem;
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;
    }
    .featured-games-homepage-big-picture-left-column {
      position: relative;
      height: 50%;
      width: 50%;
      overflow: hidden;
    }
    .featured-games-homepage-big-picture-left-column img {
      display: inline-block;
      height: 18rem;
      width: 27rem;
      border-radius: 10px;
      transition: -webkit-filter 0.3s;
      transition: filter 0.3s;
      transition: filter 0.3s, -webkit-filter 0.3s;
    }
    .featured-games-homepage-little-pictures-right-column {
      margin-left: 10rem;
      
    }
    /* You may need additional adjustments for specific elements */
    /* Example: */
    /* .item {
          width: 100%; /* Make items full width */
       }

.pop-image {
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s;
}

.pop-image.clicked {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}

.carousel {
  position: relative;
  width: 70rem;
  height: 25rem;
  overflow: hidden;
  margin-top: 1.5rem;
}

.carouse2l {
  position: relative;
  width: 70rem;
  height: 20rem;
  overflow: hidden;
  margin-top: 1.5rem;
}

.carousel__prev-button,
.carousel__next-button {
  position: absolute;
  top: 50%;
  background-color: black;
  color: white;
  font-size: 18px;
  border: none;
  padding: 6px;
  margin-bottom: 2rem;
  cursor: pointer;
  z-index: 2;
}

.carousel__prev-button:hover,
.carousel__next-button:hover {
  background-color: #282A3A;
  z-index: 2;
}
.carouse2l__prev-button,
.carouse2l__next-button {
  position: absolute;
  top: 50%;
  background-color: black;
  color: white;
  font-size: 18px;
  border: none;
  padding: 6px;
  margin-bottom: 2rem;
  cursor: pointer;
  z-index: 2;
}

.carouse2l__prev-button:hover,
.carouse2l__next-button:hover {
  background-color: #282A3A;
  z-index: 2;
}

.carousel__prev-button {
  left: 10px;
}

.carousel__next-button {
  right: 10px;
}
.carouse2l__prev-button {
  left: 10px;
}

.carouse2l__next-button {
  right: 10px;
}

.carousel__slide-container {
  display: flex;
  width: 70rem;
  justify-content: space-between;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  grid-gap: 1rem;
  gap: 1rem;
  border-radius: 10px;
  height: 25rem;
}
.carouse2l__slide-container {
  display: flex;
  width: 70rem;
  justify-content: space-between;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  grid-gap: 1rem;
  gap: 1rem;
  border-radius: 10px;
  height: 25rem;
}

.carousel__slide {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(100% / 5);
  height: 25rem;
  background-size: cover;
  background-position: center;

  transition: opacity 0.5s ease-in-out;
}
.carouse2l__slide {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(100% / 4);
  height: 25rem;
  background-size: cover;
  background-position: center;

  transition: opacity 0.5s ease-in-out;
}

.carousel__slide.active {
  opacity: 1;
}


.single-game-details-page-carousel-image {
  height: 15rem;
  width: 100%;

}
.homepage-details-page-carousel-image {
  height: 20rem;
  width: 15rem;

  border-radius: 5px;
  cursor: pointer;
}

.homepage-details-page-carousel-image:hover {
  -webkit-filter: brightness(120%);
          filter: brightness(120%);
}
.single-game-details-page-carousel-image:hover {
  -webkit-filter: brightness(120%);
          filter: brightness(120%);
}

.game-name,
.game-price {
  color: white;

}


@media only screen and (max-width: 680px) {
  .carousel img {
    width: 50%;
  }
  .carousel__slide {
   height: 40%;
   width: 40%;
  }

  .carousel {
    width: 23rem;
  }

  .carousel__slide-container {
    width: 23rem;
  }

  .carouse2l__slide-container {
    width: 23rem;
  }
}

.top-bar-homepage-container {
    display: flex;
    justify-content: space-between;
}

.top-bar-homepage-container-left-column {
    display: flex;
    align-items: center;
    text-align: center;
    width: 50rem;
}

.top-bar-homepage-container-right-column {
    display: flex;
    align-items: center;
}

.hover-effect:hover {
    color: white;
}

@media only screen and (max-width: 680px) {
    .search-bar {
        display: none;
    }
    .top-bar-homepage-container {
        display: flex;
        justify-content: space-between;
        width: 23rem;
    }
    .top-bar-homepage-container-left-column {
        display: flex;
        align-items: center;
        text-align: center;
        width: 15rem;
    }
    .gaming-marketplace-container {
        font-size: .45rem;
        margin-left: 24.5rem;
    }

}

.three-games-card-container {
    display: flex;
    justify-content: space-between;
    grid-gap: 1rem;
    gap: 1rem;
    /* margin-top: 4rem; */
    height: 25rem;
  }

  .three-games-card {
    width: calc(100% / 2);
    padding: 1rem;
    border-radius: 4px;
  }

  /* .three-games-card img {

  } */

  .three-games-card-image {
      height: 18rem;
      width: auto;
    /* max-height: 200px; */
    /* object-fit: cover; */
    border-radius: 4px;
    cursor: pointer;
  }

  .three-games-card-name {
    margin-top: .2rem;
    font-size: 1rem;
    color: white;
  }

  .three-games-card-price {
    margin-top: 0.1rem;
    font-size: 1rem;
    color: white;
  }

  @media only screen and (max-width: 680px) {
    .three-games-card-image {
      width: 90%;
    }
}

.footer {
    background-color: #141414;
    color: #ffffff;
    padding: 2rem;
}

.footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.footer-column {
    flex: 0 0 33.33%;
}


.footer-column-title {
    color: gray;
    font-size: .8rem;
    margin-bottom: 1rem;
}

.footer-links {
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer-link {
    font-size: 0.7rem;
    margin-bottom: 0.5rem;
    cursor: pointer;
}

.footer-link:hover {
    text-decoration: underline;
}

.footer-bottom {
    margin-top: 2rem;
    text-align: center;
}

.footer-text {
    font-size: 0.8rem;
}

/* .loading-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300vh;
    background-color: black;
  } */

  /* .loading-screen {
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgba(0, 0, 0, 0.5);
    z-index: 8;
  } */

  /* .loading-text {
    color: white;
    font-size: 24px;
    margin-top: 10px;
    background-color: black;
  }

  .loading-gif {
    height: 40rem;
    width: auto;
  } */
  /* .loading-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: black;
    height: 100vh;
  }

  .spinner {
    width: 20rem;
    height: 20rem;
    border: 3rem solid white;
    border-right: 3rem solid #C69749;

    border-radius: 50%;
    font-weight: bold;
    animation: spin .5s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .loading-text {
    margin-top: 10rem;
    font-weight: bold;
    font-size: 30px;
    color: white;
  } */

  /* @keyframes pulse {
    0% {
      transform: scale(0.4);
      opacity: 0.3;
    }
    50% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(0.4);
      opacity: 0.7;
    }
  }

  .loading-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    background-color: black;
  }

  .pulse-loader {
    width: 20rem;
    height: 20rem;
    background-color: #C69749;
    border-radius: 50%;
    animation: pulse .3s ease-in-out infinite;
  } */
  @-webkit-keyframes blink {
    0%, 49% {
      opacity: .5;
    }
    50% {
      opacity: .7;
    }
    100% {
        opactiy: 1;
    }
  }
  @keyframes blink {
    0%, 49% {
      opacity: .5;
    }
    50% {
      opacity: .7;
    }
    100% {
        opactiy: 1;
    }
  }

  .loading-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: black;
  }

  .blinking-dots {
    display: flex;
    align-items: center;
    margin-top: 4rem;
  }

  .dot {
    width: 2rem;
    height: 2rem;
    margin-right: 5px;
    background-color: #C69749;
    border-radius: 50%;
    -webkit-animation: blink 1s infinite;
            animation: blink 1s infinite;
  }

  .dot1 {
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
  }

  .dot2 {
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
  }

  .dot3 {
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
  }

  /* .loading-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: black
  }

  .line-container {
    width: 20rem;
    height: 10px;
    background-color: #282A3A;
    position: relative;
  }

  .line {
    width: 5rem;
    height: 100%;
    background-color: #C69749;
    position: absolute;
    animation: line-progress .5s infinite linear;
  }

  @keyframes line-progress {
    0% {
      left: -20px;
      width: 20px;
    }
    50% {
      left: 100%;
      width: 0;
    }
    100% {
      left: 100%;
      width: 0;
    }
  } */

.homepage-regular-card-container {
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
  }

  .five-games-card {
    width: 18%
  }

  .five-games-card-image {
    width: 100%;
    height: 18rem;
    cursor: pointer;
  }

.game-developer-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-top: 3rem;
}

.form-container {
    max-width: 1500px;
}

.form-row input[type="text"],
.form-row textarea {
    background-color: #f7f7f7;
}

.form-row input[type="text"],
.form-row textarea {
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  margin-top: 0.5rem;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif
}

.form-row textarea {
  resize: vertical;
  min-height: 8rem;
}
.form-row label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    margin-top: 1.5rem;
  }

button[type="submit"]:hover {
    background-color: #C69749;
    cursor: pointer;
  }

.game-developer-page-outer-wrapper {
    display: flex;
    justify-content: center;
    min-height: 100rem;
    background-color: black;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 3px;
}

.game-developer-page-inner-wrapper {
    display: flex;
    flex-direction: column;
    background-color: black;
    width: 70rem;
    min-height: 60rem;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 3px;
    margin-top: 5.5rem;
}

.game-developer-page-inner-wrapper h1 {
    color: white;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    text-align: center;
}

.gif-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32rem;
    background-color: #282A3A;
    margin-top: 3rem;
}

.gif-container img {
    max-width: 65%;
    border-radius: 5px;
}

.overlay-text {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #282A3A;
    color: white;
    /* padding: 2rem; */
}

.overlay-text a:hover {
    background-color: #735F32;
}

.overlay-text h1 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
}

.overlay-text p {
    font-size: 1rem;
    margin-bottom: 1.5rem;
}

.overlay-text a {
    font-size: 1rem;
    text-decoration: none;
    color: white;
    border: 1px solid white;
    padding: 0.5rem 1rem;
    box-shadow: 5px 5px 5px gray;
    margin-bottom: 2rem;
}

.game-developer-page-button {
    margin-bottom: 3rem;
}

.column-container {
    display: flex;
    justify-content: space-between;
}

.column {
    flex: 1 1;
}

.column-item {
    background-color: lightgray;
    padding: 10px;
    margin-bottom: 10px;
}

@media only screen and (max-width: 680px) {
    .gif-container img {
        display: none;
    }
  }

.single-details-page-wrapper {
    display: flex;
    justify-content: center;
    min-height: 100rem;
    background-color: #0F0E0E;
    color: white;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 3px;
}

.single-details-page-inner-wrapper {
    display: flex;
    flex-direction: column;
    background-color: #0F0E0E;
    width: 70rem;
    min-height: 80rem;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 3px;
    margin-top: 2rem;
}


.single-details-page-left-right-column {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-height: 30rem;
    margin-bottom: 2rem;
}

.single-details-page-left-column {
    width: 100%;
}

.single-details-page-left-column > img {
    border-radius: 10px;
    height: 30rem;
    max-width: 52rem;
}

.single-game-details-right-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 20rem;
}

.add-to-cart {
    display: flex;
    justify-content: center;
    /* text-align: center; */
}

.add-to-cart:hover {
    background-color: #282A3A;
}

.singleGameDetails-game-details {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid gray;
}

.singleGameDetails-game-details-tag {
    color: rgb(143, 126, 126);
}

@media only screen and (max-width: 680px) {
    .single-details-page-inner-wrapper {
        width: 23rem;
    }
    .carouse2l {
        display: none;
    }

    .single-details-page-left-column > img {
        max-width: 10rem;
    }

    .single-game-details-right-column {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 20rem;
        margin-right: 1rem;
    }

    .singleGameDetails-game-details-tag{
        font-size: .8rem;
    }
}

.post-comment-modal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20rem;
    height: 16rem;
}

.how-stay {
    margin-bottom: 1rem;
    font-size: 1.5rem;
}

.commentModalText {
    width: 70%;
    height: 100px;
    padding: 8px;
    margin-bottom: 1rem;
    resize: vertical;
}

.post-comment-modal-button {
    padding: 8px 16px;
    background-color: #C69749;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.post-comment-modal-button:hover {
    background-color: #735F32;
}

.post-comment-modal-button.disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.game-developer-portal-outer-wrapper {
    display: flex;
    justify-content: center;
    min-height: 80rem;
    background-color: black;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 3px;
}

.game-developer-portal-inner-wrapper {
    display: flex;
    flex-direction: column;
    background-color: black;
    width: 70rem;
    min-height: 80rem;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 3px;
    margin-top: 5.5rem;
}

.submit-new-application-link {
    display: flex;
    flex-direction: column;
}

.developer-page-column-container{
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;
    width: 100%;
    height: 15rem;
}

.developer-page-column {
    display: flex;
    flex-direction: column;
    height: 15rem;
}



.developer-column-item {
    border: gray;
    border-radius: 20px;
    background-color: lightgray;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 20rem;
    text-align: center;
    height: 6rem;
    padding: 1.3rem;
    margin: 0;
    height: 25rem;
}

@media only screen and (max-width: 680px) {
.game-developer-portal-outer-wrapper {
    width: 23rem;
    }
}

.game-developer-form-container2 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 0.25rem;
    padding: 3rem;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.form-container2 {
    max-width: 1500px;
}


.form-row2 input[type="text"],
.form-row2 textarea {
background-color: #f7f7f7;
  width: 80%;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  margin-top: 0.5rem;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif
}

.form-row2 textarea {
  resize: vertical;
  min-height: 5rem;
}
.form-row2 label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
    margin-bottom: .25rem;
    margin-top: .5rem;
  }


  button[type='submit'] {
      background-color: rgb(73, 73, 3);
      cursor: pointer;
      font-weight: bold;
      box-shadow: 2px 2px 2px black;
    }

button[type="submit"]:hover {
    background-color: rgb(198, 151, 73);
    cursor: pointer;
    font-weight: bold;
    box-shadow: 2px 2px 2px black;
  }


.developer-portal-games-card-outer-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;
    margin-top: 3rem;
  }

  .developer-portal-games-card-inner-wrapper {
    display: flex;
    align-items: center;
    background-color: #735F32;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 0 2px 4px darkslategrey;
  }

  .developer-portal-games-card-left-column {
    margin-right: 2rem;
  }


  .developer-portal-update-delete-game-right-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    grid-gap: 1rem;
    gap: 1rem;
  }

.cart-page-outer-wrapper {
    display: flex;
    justify-content: center;
    min-height: 80rem;
    background-color: black;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 3px;
}

.cart-page-inner-wrapper {
    display: flex;
    flex-direction: column;
    background-color: black;
    width: 70rem;
    min-height: 80rem;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 3px;
    margin-top: 5.5rem;
}

.cart-page-checkout {
    background-color: black;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 1.5rem;
    margin-bottom: 20px;
    height: 8rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .cart-page-checkout h1 {
    font-size: 24px;
    margin-bottom: 10px;
  }

  .cart-page-checkout p {
    font-size: 1rem;
    margin-bottom: 20px;
  }

  .cart-page-checkout button {
    background-color: #282A3A;
    color: white;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
  }

  .cart-page-checkout button:hover {
    background-color: #C69749;
  }

  .cart-page-games-checkout-game-card-container {
    display: flex;
    justify-content: space-between;

  }

  .cart-page-games-card-container {
    width: 52.5rem;
    border-radius: 5px;
  }

  .checkout-modal-wrapper {
    display: flex;
    justify-content: space-between;
    border: 10px solid #282A3A;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 50rem;
    padding: 2rem;

  }

  .card-details-credit-card-details-image-wrapper{
    display: flex;
    justify-content: space-between;
  }

  .expiration-cvv-container {
    display: flex;
    justify-content: space-between;
  }

  .zip-code-state-city-container {
    display: flex;
    justify-content: space-between;
  }



  #expiration {
    width: calc(50% - 5px);
    margin-bottom: 10px;
    padding: 10px;
  }

  @media only screen and (max-width: 680px) {
    .cart-page-games-checkout-game-card-container {
      display: block;
    }
    .cart-page-outer-wrapper {
      width: 100%;
    }

    .cart-games-card-outer-wrapper {
      width: 20rem;
    }


    .cart-page-inner-wrapper {
      width: 23rem;
    }
    .cart-page-games-card-container {

    }
    .cart-games-card-classification {
      display: none;
    }
    .checkout-modal-wrapper {
      display: block;
      width: 100%;
    }

    .card-details-credit-card-details-image-wrapper {
      display: block;
    }
    .expiration-cvv-container {
      display: block;
      width: 70%;
    }
    .zip-code-state-city-container {
      display: block;
    }
    .payment-information {
      width: 40%;
    }

    .news-page-highlighted-wrapper-left-right-column {

    }

    .news-page-inner-wrapper {
      margin-left: 5rem;
      width: 23rem;
    }
  }

.cart-games-card-outer-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
    padding: 1rem;
    background-color: #282A3A;
}

.cart-games-card-left-column {
    width: 30%;
}

.cart-games-card-right-column {
    /* width: 50%; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

/* .cart-games-card-right-column p {
    color: white;
} */

.user_profile_container {
    display: flex;
    justify-content: center;
    min-height: 120rem;
    background-color: black;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 3px;
}

.user-profile-inner-container {
    display: flex;
    flex-direction: column;
    background-color: black;
    width: 70rem;
    min-height: 80rem;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 3px;
    margin-top: 5.5rem;
}

.user-profile-information {
    display: flex;
    justify-content: space-between;
    width: 70%;
    margin-top: 3rem
}

.user-profile-information-right-column {
    width: 30rem;
}

.user-profile-information-right-column-bottom-information {
    display: flex;
    flex-direction: column;
}

.installer-order-tabs {
    display: flex;
    justify-content: space-between;
    width: 30rem;
}

.installer-order-tabs h2 {
    color: white;
    cursor: pointer;
    padding: 1.5rem;
}

.table {
    width: 100%;
    border-collapse: separate;
    /* Use separate border model */
    border-spacing: 0;
    /* Set the spacing between cells to 0 */
}

.table th,
.table td {
    padding: 8px;
    border-bottom: 1px solid #ccc;
    /* Only show bottom border */
}

.table th:first-child,
.table td:first-child {
    border-left: none;
    /* Remove left border for the first column */
}

.table th:last-child,
.table td:last-child {
    border-right: none;
    /* Remove right border for the last column */
}

.table th {
    background-color: #f0f0f0;
}

.games-installer-card-outer-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 1rem;
    border: 3px solid gray;
    padding: 1rem
}

.games-installer-card-left-column {
    width:20rem;
}

.loading-bar-container {
    width: 20rem;
    height: 10rem;
}

.browse-games-pages-card-outer {
    display: flex;
    justify-content: center;
    width: 15rem;
    margin-bottom: 5rem;
    margin-top: 5rem;
}

.browse-games-pages-card-inner {
    display: flex;

    flex-direction: column;
    height: 10rem;
    /* border-radius: 10px;
    box-shadow: 0 2px 4px darkslategrey; */
}

.browse-games-pages-card-bottom {
  color: white;
}

.browse-games-pages-outer {
    display: flex;
    justify-content: center;
    min-height: 160rem;
    background-color: black;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 3px;
}

.browse-games-pages-inner {
    display: flex;
    flex-direction: column;
    background-color: black;
    width: 70rem;
    min-height: 80rem;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 3px;
    margin-top: 5.5rem;
}

.browse-games-pages-games {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 30px;
}
@media only screen and (max-width: 680px) {
    .browse-games-pages-inner {
        width: 23rem;
    }
    .browse-games-pages-games {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 3px;

    }
    .browse-games-pages-games img {
        height: 40%;
        width: 40%
    }
  }

.news-page-outer-wrapper {
  display: flex;
  justify-content: center;
  min-height: 150rem;
  background-color: black;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 3px;
  padding: 20px;
  color: white;
}

.news-page-inner-wrapper {

  max-width: 1200px;
  margin: 0 auto;
}

.news-page-inner-wrapper p {
  padding-top: 4px;
}

.news-page-inner-wrapper img {
  height: '10rem';
  cursor: pointer;
}

.news-page-inner-wrapper h2 {
  font-size: 24px;
  padding-top: 5rem;
  margin-bottom: 20px;
}

.news-page-highlighted-wrapper-left-right-column {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 5rem;
}

.news-page-highlighted-wrapper-left-column,
.news-page-highlighted-wrapper-right-column {
  width: 48%;
}

.news-page-highlighted-wrapper-left-column img,
.news-page-highlighted-wrapper-right-column img {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.news-page-article-card-wrapper {
  display: flex;
  margin-bottom: 20px;
}

.news-page-article-card-left-column {
  flex: 0 0 30%;
  margin-right: 10px;
}

.news-page-article-card-left-column img {
  width: 20rem;
  height: 20rem;
}

.news-page-article-card-right-column {
  flex: 1 1;
}

.news-page-article-card-right-column p {
  margin-bottom: 10px;
}

.news-page-article-card-right-column p:first-child {
  font-weight: bold;
}

.news-page-article-card-right-column p:last-child {
  margin-bottom: 0;
}


.new-page-bottom-news-list-wrapper {
  margin-top: 7rem;
}

@media only screen and (max-width: 680px) {
  .news-page-article-card-wrapper {
    display: block;
  }

}

.card-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    max-width: 400px;
    margin: 0 auto;
}

.card {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    background-color: #C69749;
    border-radius: 5px;
    font-size: 24px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.card.flipped {
    background-color: white;
}

.card.matched {
    background-color: #735F32;
    cursor: default;
}

.news-article-outer-container {
    display: flex;
    justify-content: center;
    min-height: 150rem;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 3px;
}

.news-article-inner-container {
    display: flex;
    flex-direction: column;
    width: 100rem;
    min-height: 80rem;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 3px;
    grid-gap: 2rem;
    gap: 2rem;
    /* margin-top: 5.5rem; */
}




/* TODO Add site wide styles */

* {
    margin: 0;
    padding: 0;
    font-family: Poppins;
}

img {
    object-fit: cover;
}

.form-row p {
    color: red;
}

