.post-comment-modal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20rem;
    height: 16rem;
}

.how-stay {
    margin-bottom: 1rem;
    font-size: 1.5rem;
}

.commentModalText {
    width: 70%;
    height: 100px;
    padding: 8px;
    margin-bottom: 1rem;
    resize: vertical;
}

.post-comment-modal-button {
    padding: 8px 16px;
    background-color: #C69749;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.post-comment-modal-button:hover {
    background-color: #735F32;
}

.post-comment-modal-button.disabled {
    background-color: #ccc;
    cursor: not-allowed;
}
