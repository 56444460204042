.footer {
    background-color: #141414;
    color: #ffffff;
    padding: 2rem;
}

.footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.footer-column {
    flex: 0 0 33.33%;
}


.footer-column-title {
    color: gray;
    font-size: .8rem;
    margin-bottom: 1rem;
}

.footer-links {
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer-link {
    font-size: 0.7rem;
    margin-bottom: 0.5rem;
    cursor: pointer;
}

.footer-link:hover {
    text-decoration: underline;
}

.footer-bottom {
    margin-top: 2rem;
    text-align: center;
}

.footer-text {
    font-size: 0.8rem;
}
