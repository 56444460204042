.carousel {
  position: relative;
  width: 70rem;
  height: 25rem;
  overflow: hidden;
  margin-top: 1.5rem;
}

.carouse2l {
  position: relative;
  width: 70rem;
  height: 20rem;
  overflow: hidden;
  margin-top: 1.5rem;
}

.carousel__prev-button,
.carousel__next-button {
  position: absolute;
  top: 50%;
  background-color: black;
  color: white;
  font-size: 18px;
  border: none;
  padding: 6px;
  margin-bottom: 2rem;
  cursor: pointer;
  z-index: 2;
}

.carousel__prev-button:hover,
.carousel__next-button:hover {
  background-color: #282A3A;
  z-index: 2;
}
.carouse2l__prev-button,
.carouse2l__next-button {
  position: absolute;
  top: 50%;
  background-color: black;
  color: white;
  font-size: 18px;
  border: none;
  padding: 6px;
  margin-bottom: 2rem;
  cursor: pointer;
  z-index: 2;
}

.carouse2l__prev-button:hover,
.carouse2l__next-button:hover {
  background-color: #282A3A;
  z-index: 2;
}

.carousel__prev-button {
  left: 10px;
}

.carousel__next-button {
  right: 10px;
}
.carouse2l__prev-button {
  left: 10px;
}

.carouse2l__next-button {
  right: 10px;
}

.carousel__slide-container {
  display: flex;
  width: 70rem;
  justify-content: space-between;
  transition: transform 0.3s ease-in-out;
  gap: 1rem;
  border-radius: 10px;
  height: 25rem;
}
.carouse2l__slide-container {
  display: flex;
  width: 70rem;
  justify-content: space-between;
  transition: transform 0.3s ease-in-out;
  gap: 1rem;
  border-radius: 10px;
  height: 25rem;
}

.carousel__slide {
  flex: 0 0 calc(100% / 5);
  height: 25rem;
  background-size: cover;
  background-position: center;

  transition: opacity 0.5s ease-in-out;
}
.carouse2l__slide {
  flex: 0 0 calc(100% / 4);
  height: 25rem;
  background-size: cover;
  background-position: center;

  transition: opacity 0.5s ease-in-out;
}

.carousel__slide.active {
  opacity: 1;
}


.single-game-details-page-carousel-image {
  height: 15rem;
  width: 100%;

}
.homepage-details-page-carousel-image {
  height: 20rem;
  width: 15rem;

  border-radius: 5px;
  cursor: pointer;
}

.homepage-details-page-carousel-image:hover {
  filter: brightness(120%);
}
.single-game-details-page-carousel-image:hover {
  filter: brightness(120%);
}

.game-name,
.game-price {
  color: white;

}


@media only screen and (max-width: 680px) {
  .carousel img {
    width: 50%;
  }
  .carousel__slide {
   height: 40%;
   width: 40%;
  }

  .carousel {
    width: 23rem;
  }

  .carousel__slide-container {
    width: 23rem;
  }

  .carouse2l__slide-container {
    width: 23rem;
  }
}
