.cart-games-card-outer-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
    padding: 1rem;
    background-color: #282A3A;
}

.cart-games-card-left-column {
    width: 30%;
}

.cart-games-card-right-column {
    /* width: 50%; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

/* .cart-games-card-right-column p {
    color: white;
} */
