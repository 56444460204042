.games-installer-card-outer-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 1rem;
    border: 3px solid gray;
    padding: 1rem
}

.games-installer-card-left-column {
    width:20rem;
}

.loading-bar-container {
    width: 20rem;
    height: 10rem;
}
