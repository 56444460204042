.featured-games-homepage-wrapper {
    display: grid;
    grid-template-columns: 5fr 1fr;
    grid-gap: 0.5rem;
    width: 100%;
    height: 35rem;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .featured-games-homepage-big-picture-left-column {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  .featured-games-homepage-big-picture-left-column.slide-in img {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    /* transform: translateX(100%); */
    transition: transform 0.2s ease;
  }

  /* .featured-games-homepage-big-picture-left-column.slide-in img:first-child {
    transform: translateX(0%);
  } */

  .featured-games-homepage-big-picture-left-column.slide-in img {
    transform: translateX(-100%);
    opacity: .25;
  }

  .featured-games-homepage-big-picture-left-column img {
    display: inline-block;
    height: 36rem;
    width: 55rem;
    border-radius: 10px;
    transition: filter 0.3s;
  }

  .featured-games-homepage-big-picture-left-column img:hover {
    filter: brightness(0.9);
    cursor: pointer;
  }

  .featured-games-homepage-little-pictures-right-column {
    display: flex;
    flex-direction: column;
    padding: 0.2rem;
  }

  .featured-games-homepage-little-pictures-right-column-container {
    display: flex;
    transition: transform 0.5s ease;
  }

  .featured-games-homepage-little-pictures-right-column-image-title-card {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 12px;
    color: white;
    border-radius: 5px;
    margin: 2px;
    cursor: pointer;
  }

  .featured-games-homepage-little-pictures-right-column-image-title-card:hover {
    background-color: rgb(40, 42, 58);
  }

  .featured-games-homepage-little-pictures-right-column-image-title-card img {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4.6rem;
    width: 6rem;
    padding: 0.5rem;
    border-radius: 5px;
  }

  .featured-games-homepage-little-pictures-right-column-image-title-card-image-title {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  @media only screen and (max-width: 680px) {
    /* Adjust the layout to stack elements vertically */
    /* You can target specific elements as needed */
    /* .featured-games-homepage-wrapper {

      flex-direction: column;
    } */
    .featured-games-homepage-wrapper {
      display: grid;
      grid-template-columns: 5fr 1fr;
      grid-gap: 0.5rem;
      width: 60%;
      height: 35rem;
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;
    }
    .featured-games-homepage-big-picture-left-column {
      position: relative;
      height: 50%;
      width: 50%;
      overflow: hidden;
    }
    .featured-games-homepage-big-picture-left-column img {
      display: inline-block;
      height: 18rem;
      width: 27rem;
      border-radius: 10px;
      transition: filter 0.3s;
    }
    .featured-games-homepage-little-pictures-right-column {
      margin-left: 10rem;
      
    }
    /* You may need additional adjustments for specific elements */
    /* Example: */
    /* .item {
          width: 100%; /* Make items full width */
       }
