.user_profile_container {
    display: flex;
    justify-content: center;
    min-height: 120rem;
    background-color: black;
    height: fit-content;
    border-radius: 3px;
}

.user-profile-inner-container {
    display: flex;
    flex-direction: column;
    background-color: black;
    width: 70rem;
    min-height: 80rem;
    height: fit-content;
    border-radius: 3px;
    margin-top: 5.5rem;
}

.user-profile-information {
    display: flex;
    justify-content: space-between;
    width: 70%;
    margin-top: 3rem
}

.user-profile-information-right-column {
    width: 30rem;
}

.user-profile-information-right-column-bottom-information {
    display: flex;
    flex-direction: column;
}

.installer-order-tabs {
    display: flex;
    justify-content: space-between;
    width: 30rem;
}

.installer-order-tabs h2 {
    color: white;
    cursor: pointer;
    padding: 1.5rem;
}
