.browse-games-pages-outer {
    display: flex;
    justify-content: center;
    min-height: 160rem;
    background-color: black;
    height: fit-content;
    border-radius: 3px;
}

.browse-games-pages-inner {
    display: flex;
    flex-direction: column;
    background-color: black;
    width: 70rem;
    min-height: 80rem;
    height: fit-content;
    border-radius: 3px;
    margin-top: 5.5rem;
}

.browse-games-pages-games {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 30px;
}
@media only screen and (max-width: 680px) {
    .browse-games-pages-inner {
        width: 23rem;
    }
    .browse-games-pages-games {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 3px;

    }
    .browse-games-pages-games img {
        height: 40%;
        width: 40%
    }
  }
