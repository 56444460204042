.game-developer-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
    margin-top: 3rem;
}

.form-container {
    max-width: 1500px;
}

.form-row input[type="text"],
.form-row textarea {
    background-color: #f7f7f7;
}

.form-row input[type="text"],
.form-row textarea {
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  margin-top: 0.5rem;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif
}

.form-row textarea {
  resize: vertical;
  min-height: 8rem;
}
.form-row label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    margin-top: 1.5rem;
  }

button[type="submit"]:hover {
    background-color: #C69749;
    cursor: pointer;
  }
