.developer-portal-games-card-outer-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;
    margin-top: 3rem;
  }

  .developer-portal-games-card-inner-wrapper {
    display: flex;
    align-items: center;
    background-color: #735F32;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 0 2px 4px darkslategrey;
  }

  .developer-portal-games-card-left-column {
    margin-right: 2rem;
  }


  .developer-portal-update-delete-game-right-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    gap: 1rem;
  }
