.top-right-wrapper {
    display: flex;
    align-items: center;
}

.profile-button-login-wrapper {
    display: flex;
    flex-direction: column;
}

.profile-dropdown {
    background-color: rgb(40, 42, 58);
    width: 10rem;
    margin-right: 4rem;
    text-align: center;
    border-radius: 10px;
    padding-bottom: .5rem;
    padding-top: .5rem;
    border: 2px solid #C69749;
}

@media only screen and (max-width: 680px) {
    .navbar-wrapper {
      width: 100%;
    }
    .profile-dropdown {
        margin-right: 20rem;
    }
  }
