.browse-games-pages-card-outer {
    display: flex;
    justify-content: center;
    width: 15rem;
    margin-bottom: 5rem;
    margin-top: 5rem;
}

.browse-games-pages-card-inner {
    display: flex;

    flex-direction: column;
    height: 10rem;
    /* border-radius: 10px;
    box-shadow: 0 2px 4px darkslategrey; */
}

.browse-games-pages-card-bottom {
  color: white;
}
