.card-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    max-width: 400px;
    margin: 0 auto;
}

.card {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    background-color: #C69749;
    border-radius: 5px;
    font-size: 24px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.card.flipped {
    background-color: white;
}

.card.matched {
    background-color: #735F32;
    cursor: default;
}
