.cart-page-outer-wrapper {
    display: flex;
    justify-content: center;
    min-height: 80rem;
    background-color: black;
    height: fit-content;
    border-radius: 3px;
}

.cart-page-inner-wrapper {
    display: flex;
    flex-direction: column;
    background-color: black;
    width: 70rem;
    min-height: 80rem;
    height: fit-content;
    border-radius: 3px;
    margin-top: 5.5rem;
}

.cart-page-checkout {
    background-color: black;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 1.5rem;
    margin-bottom: 20px;
    height: 8rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .cart-page-checkout h1 {
    font-size: 24px;
    margin-bottom: 10px;
  }

  .cart-page-checkout p {
    font-size: 1rem;
    margin-bottom: 20px;
  }

  .cart-page-checkout button {
    background-color: #282A3A;
    color: white;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
  }

  .cart-page-checkout button:hover {
    background-color: #C69749;
  }

  .cart-page-games-checkout-game-card-container {
    display: flex;
    justify-content: space-between;

  }

  .cart-page-games-card-container {
    width: 52.5rem;
    border-radius: 5px;
  }

  .checkout-modal-wrapper {
    display: flex;
    justify-content: space-between;
    border: 10px solid #282A3A;
    height: fit-content;
    width: 50rem;
    padding: 2rem;

  }

  .card-details-credit-card-details-image-wrapper{
    display: flex;
    justify-content: space-between;
  }

  .expiration-cvv-container {
    display: flex;
    justify-content: space-between;
  }

  .zip-code-state-city-container {
    display: flex;
    justify-content: space-between;
  }



  #expiration {
    width: calc(50% - 5px);
    margin-bottom: 10px;
    padding: 10px;
  }

  @media only screen and (max-width: 680px) {
    .cart-page-games-checkout-game-card-container {
      display: block;
    }
    .cart-page-outer-wrapper {
      width: 100%;
    }

    .cart-games-card-outer-wrapper {
      width: 20rem;
    }


    .cart-page-inner-wrapper {
      width: 23rem;
    }
    .cart-page-games-card-container {

    }
    .cart-games-card-classification {
      display: none;
    }
    .checkout-modal-wrapper {
      display: block;
      width: 100%;
    }

    .card-details-credit-card-details-image-wrapper {
      display: block;
    }
    .expiration-cvv-container {
      display: block;
      width: 70%;
    }
    .zip-code-state-city-container {
      display: block;
    }
    .payment-information {
      width: 40%;
    }

    .news-page-highlighted-wrapper-left-right-column {

    }

    .news-page-inner-wrapper {
      margin-left: 5rem;
      width: 23rem;
    }
  }
