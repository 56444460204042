/* .loading-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300vh;
    background-color: black;
  } */

  /* .loading-screen {
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgba(0, 0, 0, 0.5);
    z-index: 8;
  } */

  /* .loading-text {
    color: white;
    font-size: 24px;
    margin-top: 10px;
    background-color: black;
  }

  .loading-gif {
    height: 40rem;
    width: auto;
  } */
  /* .loading-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: black;
    height: 100vh;
  }

  .spinner {
    width: 20rem;
    height: 20rem;
    border: 3rem solid white;
    border-right: 3rem solid #C69749;

    border-radius: 50%;
    font-weight: bold;
    animation: spin .5s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .loading-text {
    margin-top: 10rem;
    font-weight: bold;
    font-size: 30px;
    color: white;
  } */

  /* @keyframes pulse {
    0% {
      transform: scale(0.4);
      opacity: 0.3;
    }
    50% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(0.4);
      opacity: 0.7;
    }
  }

  .loading-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    background-color: black;
  }

  .pulse-loader {
    width: 20rem;
    height: 20rem;
    background-color: #C69749;
    border-radius: 50%;
    animation: pulse .3s ease-in-out infinite;
  } */
  @keyframes blink {
    0%, 49% {
      opacity: .5;
    }
    50% {
      opacity: .7;
    }
    100% {
        opactiy: 1;
    }
  }

  .loading-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: black;
  }

  .blinking-dots {
    display: flex;
    align-items: center;
    margin-top: 4rem;
  }

  .dot {
    width: 2rem;
    height: 2rem;
    margin-right: 5px;
    background-color: #C69749;
    border-radius: 50%;
    animation: blink 1s infinite;
  }

  .dot1 {
    animation-delay: 0s;
  }

  .dot2 {
    animation-delay: 0.2s;
  }

  .dot3 {
    animation-delay: 0.4s;
  }

  /* .loading-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: black
  }

  .line-container {
    width: 20rem;
    height: 10px;
    background-color: #282A3A;
    position: relative;
  }

  .line {
    width: 5rem;
    height: 100%;
    background-color: #C69749;
    position: absolute;
    animation: line-progress .5s infinite linear;
  }

  @keyframes line-progress {
    0% {
      left: -20px;
      width: 20px;
    }
    50% {
      left: 100%;
      width: 0;
    }
    100% {
      left: 100%;
      width: 0;
    }
  } */
