.game-developer-portal-outer-wrapper {
    display: flex;
    justify-content: center;
    min-height: 80rem;
    background-color: black;
    height: fit-content;
    border-radius: 3px;
}

.game-developer-portal-inner-wrapper {
    display: flex;
    flex-direction: column;
    background-color: black;
    width: 70rem;
    min-height: 80rem;
    height: fit-content;
    border-radius: 3px;
    margin-top: 5.5rem;
}

.submit-new-application-link {
    display: flex;
    flex-direction: column;
}

.developer-page-column-container{
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;
    width: 100%;
    height: 15rem;
}

.developer-page-column {
    display: flex;
    flex-direction: column;
    height: 15rem;
}



.developer-column-item {
    border: gray;
    border-radius: 20px;
    background-color: lightgray;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 20rem;
    text-align: center;
    height: 6rem;
    padding: 1.3rem;
    margin: 0;
    height: 25rem;
}

@media only screen and (max-width: 680px) {
.game-developer-portal-outer-wrapper {
    width: 23rem;
    }
}
