/* TODO Add site wide styles */
@import url('https://fonts.googleapis.com/css?family=Poppins');

* {
    margin: 0;
    padding: 0;
    font-family: Poppins;
}

img {
    object-fit: cover;
}

.form-row p {
    color: red;
}
